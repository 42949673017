import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import {
    PollSingle,
    PollResponse,
    SessionDataObject
} from "@/types/interfaces";
import { parseISO, isAfter } from "date-fns";
import Store from "../index";

const endpoint = "polls";

interface SinglePollResponsesInput {
    sessionId: string;
    pollId: string;
}

@Module({
    dynamic: true,
    store: Store,
    name: "PollsModule",
    namespaced: true
})
export default class Polls extends VuexModule {
    polls: Array<PollSingle> = [];
    myResponses: Array<PollResponse> = [];
    singlePollResponses: Array<PollResponse> = [];
    sessionData?: SessionDataObject;

    @Mutation
    public setPolls(data: Array<PollSingle>) {
        this.polls = data;
    }

    @Mutation
    public setMyResponses(data: Array<PollResponse>) {
        this.myResponses = data;
    }

    @Mutation
    public setSinglePollResponses(data: Array<PollResponse>) {
        this.singlePollResponses = data;
    }

    @Mutation
    public setSessionData(data: SessionDataObject) {
        this.sessionData = data;
    }

    get user() {
        return Store.getters.userInfo;
    }

    get session() {
        return this.sessionData;
    }

    get isAfterSession() {
        const sessionEnd =
            this.sessionData && this.sessionData.endTime
                ? this.sessionData.endTime
                : "";

        const serverTime = window.MgServerTime;
        const endTime = parseISO(sessionEnd);

        return isAfter(serverTime, endTime);
    }

    get visiblePolls() {
        const isAfterSession = isAfter(
            window.MgServerTime,
            parseISO(
                this.sessionData && this.sessionData.endTime
                    ? this.sessionData.endTime
                    : ""
            )
        );

        return this.polls.filter((poll: PollSingle) => {
            if (
                isAfterSession &&
                !poll.allowVotingAfterSession &&
                poll.afterSessionHide
            ) {
                return false;
            }

            const allowedTypes =
                Array.isArray(poll.attendeeTypesAllowed) &&
                poll.attendeeTypesAllowed.length
                    ? poll.attendeeTypesAllowed
                    : [];

            const canAccess =
                !allowedTypes.length ||
                allowedTypes.includes(this.user.attendeeType);

            if (poll.hideIfRestricted && !canAccess) {
                return false;
            }

            return true;
        });
    }

    @Action({ commit: "setPolls" })
    getPollsForSession(sessionId: string) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${endpoint}/${sessionId}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    let items: Array<PollSingle> = [];

                    if (response && Array.isArray(response.data)) {
                        response.data.forEach((item) => {
                            // We're not supporting multiple pages, so rearrange the structure.
                            const poll: PollSingle = {
                                ...item,
                                pages: [
                                    {
                                        name: "page1",
                                        elements: item.questions
                                    }
                                ]
                            };

                            delete item.questions;

                            items.push(poll);
                        });
                    } else {
                        items = [];
                    }

                    return resolve(items);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ commit: "setSessionData" })
    setThisSessionData(data: SessionDataObject) {
        return data;
    }

    @Action({ commit: "setSinglePollResponses" })
    getSinglePollResponses(data: SinglePollResponsesInput) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .post(`/${endpoint}/results`, data, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    let items: Array<PollResponse> = [];

                    if (response && Array.isArray(response.data)) {
                        response.data.forEach((item) => {
                            items.push(item);
                        });
                    } else {
                        items = [];
                    }

                    return resolve(items);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ commit: "setMyResponses" })
    getMyResponses(sessionId: string) {
        const token = this.context.rootGetters.idToken;
        const userId = Store.getters.user.id;

        return new Promise((resolve, reject) => {
            getApiClient()
                .post(
                    `/${endpoint}/results`,
                    {
                        sessionId: sessionId,
                        userId: userId
                    },
                    {
                        headers: {
                            Authorization: `bearer ${token}`
                        }
                    }
                )
                .then((response) => {
                    let items: Array<PollResponse> = [];

                    if (response && Array.isArray(response.data)) {
                        response.data.forEach((item) => {
                            items.push(item);
                        });
                    } else {
                        items = [];
                    }

                    return resolve(items);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }
}
