















// Setup
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

// Utility

// Types
import { ContEdQuestion } from "@/types/interfaces";

// Components
import * as SurveyVue from "survey-vue";
const Survey = SurveyVue.Survey;

// Vuex modules
import logVuexModule from "@/store/vuex-modules/log";
const logStore = getModule(logVuexModule);

@Component({
    components: {
        Survey
    }
})
export default class ContinuingEdQuestions extends Vue {
    @Prop({ default: 0 })
    timeSinceStart?: number;

    @Prop({ required: true })
    questions?: Array<ContEdQuestion>;

    actualQuestions: Array<ContEdQuestion> = [];
    activeQuestion: ContEdQuestion | null = null;
    surveyObj: SurveyVue.SurveyModel | null = null;

    get timeSinceStartInMinutes() {
        return this.timeSinceStart ? Math.floor(this.timeSinceStart / 60) - this.earlyMinutes : 0;
    }

    get isModalActive() {
        return this.activeQuestion !== null;
    }

    get user() {
        return this.$store.getters.userInfo;
    }

    get isProduction() {
        return this.$store.getters.isProduction;
    }

    get earlyMinutes() {
        return this.$store.state.settingsVuexModule.sessionEarlyEntryMinutes;
    }

    @Watch("timeSinceStartInMinutes")
    maybeActivateQuestion(newVal: number) {
        if (
            this.activeQuestion &&
            this.activeQuestion.actualEndTime &&
            this.activeQuestion.actualEndTime <= newVal
        ) {
            this.endSurveyUnanswered();            
        }

        if (!this.activeQuestion) {
            const activeQuestion = this.actualQuestions.find((question) => {
                return (
                    !question.isComplete &&
                    question.actualStartTime &&
                    newVal >= question.actualStartTime
                );
            });

            if (activeQuestion) {
                this.activeQuestion = activeQuestion;
                this.initializeSurvey();
            }
        }
    }    

    created() {
        this.setupActualQuestions();
    }

    setupActualQuestions() {
        if (!this.questions) {
            return [];
        }

        this.questions.forEach((question) => {            
            const newQ = { ...question };

            newQ.actualStartTime =
                newQ.minTimeAfterStart +
                Math.floor(Math.random() * newQ.variance);
            const timeAllowed = newQ.timeAllowed ? newQ.timeAllowed : 2;
            newQ.actualEndTime = newQ.actualStartTime + timeAllowed;
            newQ.isComplete = false;

            if (newQ.actualEndTime <= this.timeSinceStartInMinutes) {
                newQ.isComplete = true;
            }            

            this.actualQuestions.push(newQ);
        });
    }

    initializeSurvey() {
        if (this.activeQuestion) {
            const modelInput = {
                pages: [
                    {
                        name: "page1",
                        elements: [this.activeQuestion.question]
                    }
                ]
            };
            this.surveyObj = new SurveyVue.Model(modelInput);
            this.surveyObj.onComplete.add(this.completeSurvey);
        }
    }

    deactivateSurvey() {
        if (this.surveyObj) {
            this.surveyObj.clear(true, true);
            this.surveyObj.render();

            this.surveyObj = null;
        }
    }

    // TODO: Create or discover interface for SurveyJS options to get rid of any type
    completeSurvey(result: SurveyVue.SurveyModel, options: any): any {
        if (this.activeQuestion) {
            const logData = {
                type: "ContinuingEdQuestion",
                userId: this.user.id,
                userName: this.user.name,
                questionId: this.activeQuestion.id,
                questionIncomplete: false,
                answers: result.data
            };

            // Unlike other log entries, the polls' log entries actually support the functionality, so we want to log even in dev.
            if (this.isProduction) {
                logStore
                    .appendLogEntry(logData)
                    .catch((err) => console.log(err));
            } else {
                logStore
                    .appendLogEntryTest(logData)
                    .catch((err) => console.log(err));
            }

            this.activeQuestion.isComplete = true;
            this.activeQuestion = null;
        }
    }

    endSurveyUnanswered() {
        if(this.activeQuestion) {
            const logData = {
                type: "ContinuingEdQuestion",
                userId: this.user.id,
                userName: this.user.name,
                questionId: this.activeQuestion.id,
                questionIncomplete: true
            };

            // Unlike other log entries, the polls' log entries actually support the functionality, so we want to log even in dev.
            if (this.isProduction) {
                logStore
                    .appendLogEntry(logData)
                    .catch((err) => console.log(err));
            } else {
                logStore
                    .appendLogEntryTest(logData)
                    .catch((err) => console.log(err));
            }
            
            this.activeQuestion.isComplete = true;
            this.activeQuestion = null;
        }
    }
}
