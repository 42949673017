<template>
    <section>
        <div class="py-16 bg-livestream  flex justify-center">
            <div
                class="flex-1 w-full max-w-xl mx-4 md:mx-16 stream-video-wrapper md:flex shadow"
            >
                <div
                    class="vm-Player bg-black flex flex-col flex-1 z-20"
                    :style="headeroffsetHeight"
                >
                    <div class="inner m-auto w-full">
                        <div v-if="routeIsSession">
                            <div
                                v-if="loading"
                                class="aspect-ratio-16-9 bg-primary text-white"
                            >
                                <div
                                    class="absolute transform -translate-y-1/2 -translate-x-1/2 top-half left-half text-center"
                                >
                                    <strong>Thank you for joining.</strong>
                                    <div>Loading...</div>
                                </div>
                            </div>
                            <div v-else key="session-video">
                                <div
                                    v-if="loadError || !videoOptions"
                                    class="aspect-ratio-16-9 bg-black text-white"
                                    key="no-video"
                                >
                                    <strong
                                        class="absolute transform -translate-y-1/2 -translate-x-1/2 top-half left-half"
                                        >Video not found.</strong
                                    >
                                </div>
                                <div
                                    v-else-if="!hasStarted && !loadError"
                                    class="aspect-ratio-16-9 bg-primary text-white"
                                >
                                    <div
                                        class="absolute transform -translate-y-1/2 -translate-x-1/2 top-half left-half text-center"
                                    >
                                        <strong>Thank you for joining.</strong>
                                        <p v-if="timeLabel" class="font-light">
                                            Session will begin in
                                            {{ timeLabel }}.
                                        </p>
                                    </div>
                                </div>
                                <div v-else>
                                    <video-component
                                        key="has-video"
                                        :start-at="timeSinceStart"
                                        :options="videoOptions"
                                        :sessionData="session"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-else class="aspect-ratio-16-9">
                            <iframe
                                :src="
                                    'https://player.vimeo.com/video/' +
                                        $route.params.id
                                "
                                frameborder="0"
                                allow="autoplay; fullscreen"
                                allowfullscreen
                            ></iframe>
                        </div>
                    </div>
                </div>
                <div
                    class="comment-section relative bg-white flex flex-col z-20"
                >
                    <b-tabs
                        v-model="activeSidebar"
                        position="is-centered"
                        v-if="polls.length"
                        class="poll-tabs"
                        type="is-boxed"
                    >
                        <b-tab-item
                            label="Comments"
                            value="comments"
                        ></b-tab-item>
                        <b-tab-item label="Polls" value="polls"></b-tab-item>
                    </b-tabs>
                    <SessionComments
                        v-if="activeSidebar === 'comments'"
                        :enableComments="enableComments"
                    />
                    <SessionPolls v-if="activeSidebar === 'polls'" />
                </div>
            </div>
        </div>

        <div class=" bg-white flex justify-center">
            <div class="flex-1 w-full max-w-xl mx-4 md:mx-16 my-16">
                <h1 v-if="!routeIsSession && title" class="title is-3">
                    {{ title }}
                </h1>

                <div v-else-if="videoData">
                    <h1 v-if="videoData.title" class="title is-3">
                        {{ videoData.title }}
                    </h1>

                    <div
                        v-if="videoData.description"
                        class="my-3 mb-8 border-b pb-8"
                    >
                        <span v-html="videoData.description"></span>
                    </div>

                    <div
                        v-if="
                            Array.isArray(videoData.speakers) &&
                                videoData.speakers.length
                        "
                        class="content my-3"
                    >
                        <h2 class="mb-6 text-primary">Speakers</h2>

                        <div
                            class="mb-6"
                            v-for="(speaker, index) in videoData.speakers"
                            :key="index"
                        >
                            <h3 class="mb-2 text-base text-primary">
                                {{ speaker.name }}
                            </h3>
                            <div v-if="speaker.title" class="font-light mb-3">
                                {{ speaker.title }}
                            </div>
                            {{ speaker.biography }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <continuing-ed-questions
            v-if="ceQuestions && ceQuestions.length"
            :timeSinceStart="timeSinceStart"
            :questions="ceQuestions"
        />
    </section>
</template>

<script>
// Setup
import { mapGetters, mapState } from "vuex";
import { getModule } from "vuex-module-decorators";
// Utility
import axios from "axios";
import { subMinutes, differenceInSeconds, formatDistance } from "date-fns";
import { sessionHelpSessionIsOver } from "@/services/session-helpers";
// Components
import VideoComponent from "@/components/shared/VideoComponent.vue";
import SessionPolls from "@/components/sessions/SessionPolls";
import SessionComments from "@/components/sessions/SessionComments";
import ContinuingEdQuestions from "@/components/sessions/ContinuingEdQuestions";
// Vuex modules
import sessionVuexModule from "@/store/vuex-modules/getSessionData";
import pollsVuexModule from "@/store/vuex-modules/polls";

const sessionStore = getModule(sessionVuexModule);
const pollsStore = getModule(pollsVuexModule);

// TODO azurewebsites endpoints are deprecated. We need to use aws api gateway.

export default {
    name: "LiveStream",
    components: {
        VideoComponent,
        SessionPolls,
        SessionComments,
        ContinuingEdQuestions
    },
    data() {
        return {
            loadError: false,
            loading: false,
            title: "",
            videoData: null,
            videoOptions: null,
            sessionIsActiveInterval: null,
            isSessionOver: false,
            timeSinceStart: -1,
            timeLabel: "",
            headeroffsetHeight: "",
            activeSidebar: "comments"
        };
    },
    computed: {
        ...mapState(["route"]),
        ...mapGetters([
            "cssVariables",
            "layoutOptions",
            "awsConfig",
            "user",
            "sponsorVideos"
        ]),
        hasStarted() {
            return Boolean(this.timeSinceStart > -1);
        },
        myStartTime() {
            const returnValue = this.videoData?.startTime;
            return returnValue;
        },
        myEndTime() {
            const returnValue = this.videoData?.endTime;
            return returnValue;
        },
        isSponsorVideo() {
            let returnValue = false;

            if (this.videoData && "isSponsorVideo" in this.videoData) {
                returnValue = this.videoData.isSponsorVideo;
            }

            return returnValue;
        },
        polls() {
            return pollsStore.visiblePolls;
        },
        session: {
            get() {
                return sessionStore.session;
            },
            set() {
                // n/a
            }
        },
        sessionDateTime() {
            if (!this.videoData || !this.myStartTime) {
                return undefined;
            }
            return new Date(this.myStartTime);
        },
        startTimeAdjusted() {
            const startDate = this.sessionDateTime;
            const earlyMins = this.$store.state.settingsVuexModule
                .sessionEarlyEntryMinutes;
            return subMinutes(startDate, earlyMins);
        },
        routeIsSession() {
            return Boolean("Session" === this.route.name);
        },
        enableComments() {
            return Boolean(this.routeIsSession || this.videoOptions);
        },
        sponsorVideo() {
            let returnValue = null;

            if (Array.isArray(this.sponsorVideos)) {
                returnValue = this.sponsorVideos.find(
                    (item) => this.route.params.id === item.sessionId
                );
            }

            return returnValue;
        },
        ceQuestions() {
            return this.session && Array.isArray(this.session.ceQuestions)
                ? this.session.ceQuestions
                : [];
        }
    },
    created() {
        this.videoInit();

        const eL = document.getElementsByClassName("navbar-brand")[0];
        this.headeroffsetHeight = `top:${eL.offsetHeight}px`;
    },
    beforeDestroy() {
        window.clearInterval(this.sessionIsActiveInterval);
    },
    methods: {
        handleVimeo() {
            // VIMEO AND azurewebsites are deprecated but keeping active for nafc
            axios
                .get(
                    "https://vmp-back.azurewebsites.net/api/GetVideoTitle?code=Ad/U5Cmxj4sYcK1kYwFYRCxmJzRkjrdGtbYQXAr4glQ/oiik8nAacg==&id=" +
                        this.$route.params.id
                )
                .then((res) => {
                    this.title = res.body.title;
                });
        },

        handleActiveSessionCheck() {
            const session = this.session;
            const startDate = this.sessionDateTime;
            const adjustedStartTimeDate = this.startTimeAdjusted;

            // we dont care about `sessionHelpSessionIsActive` here,
            // we only care if the session has started (timeSinceStart) and if the session is over
            this.isSessionOver = sessionHelpSessionIsOver(session);

            this.timeLabel = formatDistance(window.MgServerTime, startDate);

            this.timeSinceStart = differenceInSeconds(
                window.MgServerTime,
                adjustedStartTimeDate
            );
        },
        videoInit() {
            if (this.routeIsSession) {
                this.loading = true;
                sessionStore
                    .getSingleSession({
                        sessionId: this.route.params.id,
                        tableName: this.awsConfig.sessionTable,
                        isDemoData: false
                    })
                    .then(() => {
                        this.videoData = this.session;
                        this.handleSessionLoadedActions();
                    })
                    .catch((error) => {
                        if (401 === error.response.status) {
                            this.$router.push("/401");
                        }
                        this.loadError = true;
                        this.videoData = this.sponsorVideo;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                this.handleVimeo();
            }
        },
        handleSessionLoadedActions() {
            // First
            this.handleActiveSessionCheck();

            // Second
            this.handleVideoOptions();
            this.updatePolls();

            // Finally
            this.sessionIsActiveInterval = window.setInterval(() => {
                this.handleActiveSessionCheck();
            }, 1000);
        },
        handleVideoOptions() {
            const options = {
                autoplay: true,
                controls: true,
                sources: null,
                poster: "",
                controlBar: {}
            };

            if (!this.videoData) {
                return;
            } else if (
                this.videoData.videoUrl ||
                this.videoData.videoUrlOnDemand
            ) {
                if (this.videoData.image) {
                    options.poster = this.videoData.image;
                }

                if (!this.isSessionOver) {
                    options.controlBar.progressControl = false;
                    options.sources = [
                        {
                            src: this.videoData.videoUrl,
                            type: "application/x-mpegURL"
                        }
                    ];
                } else {
                    let videoToUse = this.videoData.videoUrl;

                    if (this.videoData.videoUrlOnDemand) {
                        videoToUse = this.videoData.videoUrlOnDemand;
                    }

                    options.sources = [
                        {
                            src: videoToUse,
                            type: "application/x-mpegURL"
                        }
                    ];
                }

                this.videoOptions = options;
            }
        },
        updatePolls() {
            pollsStore.setThisSessionData(this.session);
            pollsStore.getPollsForSession(this.session.sessionId);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../styles/views/livestream";
@import "../styles/components/chat";
</style>
