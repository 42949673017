
























































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { SessionComment } from "@/types/interfaces";
import apiSvc from "@/services/api";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";

@Component({
    components: {}
})
export default class SessionComments extends Vue {
    @Prop({ default: true })
    enableComments!: boolean;

    commentIsPosting = false;
    commentsInterval?: number;
    comment = "";
    comments: Array<SessionComment> = [];
    loading = false;

    get cssVariables() {
        return this.$store.getters.cssVariables;
    }

    get user() {
        return this.$store.getters.user;
    }

    get isoLastCommentLoaded() {
        const comments = this.comments;
        let returnValue = "";
        let lastItem;

        if (Array.isArray(comments)) {
            lastItem = comments[comments.length - 1];
        }

        if (lastItem && lastItem.createdTime) {
            returnValue = lastItem.createdTime;
        }

        return returnValue;
    }

    mounted() {
        this.loadComments();
    }

    beforeDestroy() {
        this.clearCommentsInterval();
    }

    addReaction(emoji: string) {
        if (emoji != "%E2%9D%A4%EF%B8%8F") {
            this.comment += String.fromCodePoint(Number(emoji));
        } else {
            this.comment += decodeURI(emoji);
        }
    }

    loadComments() {
        const videoId = this.$route.params.id;
        const loadFrom = this.isoLastCommentLoaded;

        this.clearCommentsInterval();

        apiSvc
            .getVideoComments(videoId, loadFrom)
            .then((res) => {
                const newComments = [...this.comments, ...res.data];

                this.comments = uniqWith(newComments, isEqual);
                this.commentsInterval = window.setInterval(
                    this.loadComments,
                    5000
                );
            })
            .catch((error) => {
                console.error(error);
            });
    }

    isOwnComment(comment: SessionComment) {
        return Boolean(this.user.id === comment.attendeeId);
    }

    clearCommentsInterval() {
        window.clearInterval(this.commentsInterval);
    }

    addComment() {
        if (!this.enableComments) return;

        this.commentIsPosting = true;
        const videoId = this.$route.params.id;
        const comment = this.comment;

        this.clearCommentsInterval();

        apiSvc
            .postVideoComment(videoId, comment)
            .then(() => {
                this.loadComments();
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                this.commentIsPosting = false;
                // Only want to scrollchat after submitting a comment
                this.scrollChat();
            });

        this.comment = "";
    }

    scrollChat() {
        this.$nextTick(() => {
            const chatScroll = this.$refs["chat-scroll"] as Element;
            // scroll to bottom of chat
            if (chatScroll) {
                chatScroll.scrollTo(0, chatScroll.scrollHeight);
            }
        });
    }
}
