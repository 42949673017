





































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Survey } from "survey-vue";
import { SurveyModel } from "survey-core";
import { parseISO, isAfter } from "date-fns";
import { PollSingle, PollResponse } from "@/types/interfaces";
import svgArrowDown from "@/components/svg/svg-arrow-down.vue";

import logVuexModule from "@/store/vuex-modules/log";
const logStore = getModule(logVuexModule);

import pollsVuexModule from "@/store/vuex-modules/polls";
const pollsStore = getModule(pollsVuexModule);

import sessionVuexModule from "@/store/vuex-modules/getSessionData";
const sessionStore = getModule(sessionVuexModule);

@Component({
    components: {
        Survey,
        svgArrowDown
    }
})
export default class SessionPolls extends Vue {
    initializedSurveys: Array<SurveyModel> = [];

    mounted() {
        this.initializePolls();
    }

    get polls() {
        return pollsStore.visiblePolls.sort((a: PollSingle, b: PollSingle) => {
            const aNum = parseInt(a.pollId);
            const bNum = parseInt(b.pollId);
            return aNum - bNum;
        });
    }

    get session() {
        return sessionStore.singleSession;
    }

    get myResponses() {
        return pollsStore.myResponses;
    }

    get user() {
        return this.$store.getters.userInfo;
    }

    get isProduction() {
        return this.$store.getters.isProduction;
    }

    async initializePolls() {
        await pollsStore.getMyResponses(this.session.sessionId);

        this.polls.forEach((item: PollSingle) => {
            const responseForPoll = this.myResponses.find(
                (resp: PollResponse) => resp.pollId === item.pollId
            );
            const surveyObj = new SurveyModel(item);
            const allowedTypes =
                Array.isArray(item.attendeeTypesAllowed) &&
                item.attendeeTypesAllowed.length
                    ? item.attendeeTypesAllowed
                    : [];

            const canAccess =
                !allowedTypes.length ||
                allowedTypes.includes(this.user.attendeeType);

            const isAfterSession = isAfter(
                window.MgServerTime,
                parseISO(this.session.endTime ? this.session.endTime : "")
            );

            const preventResponse =
                responseForPoll ||
                !canAccess ||
                (!item.allowVotingAfterSession && isAfterSession);
            surveyObj.title = item.pollId;
            surveyObj.showTitle = false;

            if (item.completedHtml) {
                surveyObj.completedHtml = item.completedHtml;
            }

            if (
                !responseForPoll &&
                !item.allowVotingAfterSession &&
                isAfterSession
            ) {
                surveyObj.completedHtml = item.afterSessionHtml
                    ? item.afterSessionHtml
                    : "Sorry, you cannot participate in this poll after the session has ended.";
            }

            if (!responseForPoll && !canAccess) {
                surveyObj.completedHtml = item.notAllowedHtml
                    ? item.notAllowedHtml
                    : "Sorry, you are not permitted to participate in this poll.";
            }

            // If this user has already responded to this poll, don't allow them to do so again.
            if (preventResponse) {
                surveyObj.doComplete();
            } else {
                surveyObj.onComplete.add(this.completeSurvey);
            }

            this.initializedSurveys.push(surveyObj);
        });
    }

    // TODO: Create or discover interface for SurveyJS options to get rid of any type
    completeSurvey(result: SurveyModel, options: any): any {
        const logData = {
            type: "PollResult",
            userId: this.user.id,
            userName: this.user.name,
            pollId: result.title,
            sessionId: this.session.sessionId,
            answers: result.data
        };

        // Unlike other log entries, the polls' log entries actually support the functionality, so we want to log even in dev.
        if (this.isProduction) {
            logStore.appendLogEntry(logData).catch((err) => console.log(err));
        } else {
            logStore
                .appendLogEntryTest(logData)
                .catch((err) => console.log(err));
        }
    }

    getTitleForPoll(index: number) {
        const poll = this.polls[index];

        return poll.title ? poll.title : `Poll #${index + 1}`;
    }
}
